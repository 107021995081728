import utils from './utils/utils'

const bgEffect = {
  /******************
  num: #dots
  sd: rect width / height
    [ smaller #, larger size ]
  sp: spacing between dots
  min_d: minimum distance
        between scaled dots
  ŭ: color update var
  c: canvas
  ccontext: context
  dots: dots array
  _pt: points
  w, h: canvas width / height
  msX, msY: mouse X, mouse Y
  Pt: this x,y point function
  create: make scene
  _p: dot in dots
  dx, dy: x,y distance
  dist: distance calc
  sc: scale
  col: color
  run: animation loop
  go: initiate
  *********************/

  num: null,
  maxRow: null,
  offset: 15,
  sp: 80,
  sd: null,
  min_d: 150,
  ŭ: 0,

  c: null,
  ccontext: null,
  w: null,
  h: null,
  dots: null,
  _pt: null,

  msX: null,
  msY: null,

  Pt(x, y) {
    this.x = 0;
    this.y = 0;
  },

  initResize(){
    bgEffect.c.width = bgEffect.w = window.innerWidth;
    bgEffect.c.height = bgEffect.h = window.innerHeight;
  },

	initLoad() {
    bgEffect.setup();
		bgEffect.startBgEffect()

    document.addEventListener('mousemove', function(e) {
      var rect = e.target.getBoundingClientRect();
      bgEffect.msX = e.clientX;
      bgEffect.msY = e.clientY;
    });

    document.addEventListener('touchmove', function(e) {
      var rect = e.target.getBoundingClientRect();
      bgEffect.msX = e.touches[0].pageX;
      bgEffect.msY = e.touches[0].pageY;
    });
	},

  setup() {
    bgEffect.c =  document.getElementById('canv')
    bgEffect.ccontext = bgEffect.c.getContext('2d')
    bgEffect.w = bgEffect.c.width = window.innerWidth
    bgEffect.h = bgEffect.c.height = window.innerHeight
    bgEffect.msX = bgEffect.w * 0.5
    bgEffect.msY = bgEffect.h * 0.5
    bgEffect.sd = Math.ceil(window.innerWidth / bgEffect.sp)
    bgEffect.maxRow = Math.ceil(window.innerHeight / bgEffect.sp)
    bgEffect.num = bgEffect.sd * bgEffect.maxRow;
  },

  startBgEffect() {
    bgEffect.create();
    bgEffect._pt = new bgEffect.Pt(0, 0);
    bgEffect.run();
  },

  create() {
    bgEffect.dots = [];
    for (var i = 0; i < bgEffect.num; i++) {
      bgEffect.ŭ -= .5;
      const _p = {
        x: (i % bgEffect.sd) * bgEffect.sp + bgEffect.offset,
        y: Math.floor(i / bgEffect.sd) * bgEffect.sp + bgEffect.offset,
        col: 'rgba(205, 202, 197, 1)',
        sc: 1
      };

      bgEffect.dots.push(_p);
    }
  },

  run() {
    if(!utils.is_touch_device()) window.requestAnimationFrame(bgEffect.run);
    bgEffect.upd();
  },

  upd() {

    bgEffect._pt.x += parseInt((bgEffect.msX - bgEffect._pt.x) * 0.03);
    bgEffect._pt.y += parseInt((bgEffect.msY - bgEffect._pt.y) * 0.03);

    bgEffect.ccontext.clearRect(0, 0, bgEffect.c.width, bgEffect.c.height);

    for (var i = 0; i < bgEffect.num; i++) {

      const _p = bgEffect.dots[i];

      const dx = bgEffect._pt.x - _p.x;
      const dy = bgEffect._pt.y - _p.y;
      const dist = Math.sqrt(dx * dx + dy * dy);

      if (dist < bgEffect.min_d) {
        _p.sc = (bgEffect.min_d - dist) / 80 + 1.5;
      } else {
        _p.sc = 1.0;
      }

      bgEffect.ccontext.fillStyle = _p.col;
      bgEffect.ccontext.beginPath();
      bgEffect.ccontext.arc(_p.x, _p.y, 2 * _p.sc, 0, Math.PI * 2, false);
      bgEffect.ccontext.fill();
    }
  }
}

module.exports = bgEffect;
