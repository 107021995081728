import scroll from '../scroll';

const utils = {
	ins: [],
	startingDelay: ($('#preloader').hasClass('loaded')) ? 0.5 : 1.8,

	initReady() {
		utils.goToPos()
		utils.bindScrollingLink()
	},

	goToPos: (ref, offset = 0) => {
		const $ref = (typeof ref === 'string') ? $(ref) : ref;

		if ($(ref).length === 0) return false;
		const myPos = $ref.offset().top - offset;
		// scroll.goTo.scroll(myPos);
		scroll.scroller.scrollTo(myPos, true)
	},

  bindScrollingLink() {
		$(document).on('click', '.goToLink', function () {
			const target = $(this).data('target');

			if (typeof target !== 'undefined') {
				utils.goToPos(target);
			}
		});
	},

	is_touch_device() {
		const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
		const mq = function (query) {
			return window.matchMedia(query).matches;
		};

		if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
			return true;
		}

		// include the 'heartz' as a way to have a non matching MQ to help terminate the join
		// https://git.io/vznFH
		const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
		return mq(query);
	},
}

export default utils;
