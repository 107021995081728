import marquee from 'jquery.marquee';

const marqueeEffect = {
	initLoad() {
		this.initMarquee();
	},

	initMarquee() {
		const $el = $('.marquee');
		if($el.length) {

				$el.marquee({
					duration: $(window).width() > 768 ? 50 * 1000 : 10 * 1000,
					gap: 0,
					delayBeforeStart: 0,
					direction: 'left',
					duplicated: true,
					startVisible: true
				});
		}
	},
}

export default marqueeEffect;
