const maps = {
  map: null,

  initLoad() {
    if (window.sim && window.sim.map) {
      maps.map = window.sim.map;
      this.loadGmap();
    }
  },

  loadGmap: () => {
    if ($('#map').length === 0) return false;
    if (typeof google === 'undefined') {
      $.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyAySVqliaEw4KANJteyFlMp7jw3v5X3i8k', () => {
        maps.initMap();
      });
    } else {
      maps.initMap();
    }
  },

  initMap: () => {
    if ($('#map').length === 0) return false;

    const map = new google.maps.Map($('#map')[0], {
      zoom: maps.map.zoom,
      center: new google.maps.LatLng(0, 0),
      styles: [
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e9e9e9"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dedede"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#333333"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f2f2f2"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        }
    ],
    });

    // const marker = new google.maps.Marker({
    //   position: pos,
    //   icon: `${maps.pathPrefix}resources/img/marker.png`,
    //   map,
    //   url: '',
    // });

    const bounds = new google.maps.LatLngBounds();
    const infowindow = new google.maps.InfoWindow();

    const opts = {
      anchor: new google.maps.Point(20,20),
      path: 'M20.5,0C9.2,0,0,9.2,0,20.5S9.2,41,20.5,41S41,31.8,41,20.5S31.8,0,20.5,0z M21,24c-1.7,0-3-1.3-3-3s1.3-3,3-3 s3,1.3,3,3S22.7,24,21,24z',
      fillColor: '#1645D5',
      fillOpacity: 1,
      strokeWeight: 0,
      scale: .6
    };

    if($(window).outerWidth() < 768) opts.scale = 0.65

    maps.map.points.forEach(el => {
      const pos = { lat: el.lat, lng: el.lng };

      const marker = new google.maps.Marker({
        position: pos,
        icon: opts,
        map,
        url: el.url,
      });

      bounds.extend(marker.position);

      google.maps.event.addListener(marker, 'click', function () {
        window.open(this.url, '_blank');
      });
    });

    //now fit the map to the newly inclusive bounds
    map.fitBounds(bounds);
    map.panToBounds(bounds)
  },
};

module.exports = maps;
