import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

const commonAnimations = {
  initLoad() {
    this.loadcommonAnimations();
  },

  loadcommonAnimations() {
    this.splitTitle()
    this.commonRowInit()
    this.newsInit()
    this.initParnersCarousel();
    this.initProductListAnimations();
    this.initUtilityBoxesAnimations();
    this.initRelatedAnimations();
    this.initRequestRowAnimations();
    this.initHighlights();
  },

  splitTitle() {
    const commonTitle = $('.common-title .title');
    if(commonTitle.length == 0) return false

    const commonTitleSplit = new SplitText(commonTitle, {
      type: 'lines,',
      linesClass: 'split-line',
    });

    $('.common-title .split-line').wrap('<div class="line-wrapper">');

    gsap.timeline({
      scrollTrigger: {
        trigger: commonTitle,
        start: "top 75%",
      },
    })
    .from(commonTitleSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    })
  },

  commonRowInit() {
    const commonRow = $('.common-row');
    if(commonRow.length == 0) return false

    const commonRowTitle = $('.common-row__title');
    let commonRowText = $('.common-row__text');
    let commonRowTextUl = $('.common-row__text ul');

    // A volte capita di avere dei p all'interno del blocco (nel caso di un editor wysiwyg). In quel caso sovrascrivo la variabile
    if(commonRowText.find('p').length) commonRowText = commonRowText.find('p')

    const commonRowButton = $('.common-row .button');
    const commonRowImage = $('.common-row__imgWrap');
    const commonRowIcons = $('.common-row__icons');
    let commonRowTitleSplit = '';
    let commonRowTextSplit = '';

    if(commonRowTitle.length){
      commonRowTitleSplit = new SplitText(commonRowTitle, {
        type: 'lines,',
        linesClass: 'split-line',
      });
    }

    if(commonRowText.length){
      commonRowTextSplit = new SplitText(commonRowText, {
        type: 'lines,',
        linesClass: 'split-line',
      });
    }

    $('.common-row__title .split-line, .common-row__text .split-line').wrap('<div class="line-wrapper">');

    const commonTl = gsap.timeline({
      scrollTrigger: {
        trigger: commonRow,
        start: "top 75%",
      },
    })
    .addLabel('image')
    .from(commonRowImage, {
      // borderRadius: '50px',
      opacity: 0,
      scale: .9,
      ease: "power2.out",
      duration: 1.5
    }, 'image')

    if(commonRowTitle.length){
      commonTl.from(commonRowTitleSplit.lines, {
        duration: 1,
        ease: 'circ.out',
        yPercent: 100,
        stagger: 0.2,
      }, 'image+=.3')
    }
    commonTl.addLabel('start')
    if(commonRowText.length){
      commonTl.from(commonRowTextSplit.lines, {
        duration: .4,
        ease: 'circ.out',
        yPercent: 100,
        stagger: 0.1,
      }, 'start-=.5')
    }
    if(commonRowTextUl.length){
      commonTl.from(commonRowTextUl, {
        duration: .4,
        ease: 'circ.out',
        opacity: 0,
      })
    }
    commonTl.addLabel('end')
    if(commonRowButton.length){
      commonTl.from(commonRowButton, {
        opacity: 0,
        ease: "power2.out",
        duration: 1
      }, 'end-=.2')
    }
    if(commonRowIcons.length){
      commonTl.from(commonRowIcons, {
        opacity: 0,
        ease: "power2.out",
        duration: 1
      }, 'end-=.4')
    }
  },

  newsInit() {
    const newsList = $('.news-list');
    if(newsList.length == 0) return false

    const newsTitle = $('.news-list__title');
    const newsText = $('.news-list__text');
    const singleNews = $('.single-news__wrap');

    $('.news-list__title, .news-list__text').wrap('<div class="line-wrapper">');

    gsap.timeline({
      scrollTrigger: {
        trigger: newsTitle,
        start: "top 75%",
      },
    })
    .from(newsTitle, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
    }, 'start')
    .from(newsText, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
    }, 'start+=.2')
    .from(singleNews, {
      ease: 'linear',
      opacity: 0,
      stagger: .2,
      yPercent: 10
    }, 'start+=.6')
  },

  initParnersCarousel(){
    const partnersTitle = $('.partners__title');
    const partnersText = $('.partners__text');
    const partnersMarquee = $('.partners__list');
    if(partnersTitle.length == 0) return false


    partnersTitle.wrap('<div class="line-wrapper">');
    partnersText.wrap('<div class="line-wrapper">');

    const partnersTl = gsap.timeline({
      scrollTrigger: {
        trigger: partnersTitle,
        start: "top 75%",
      },
    })
    .addLabel('start')
    .from(partnersTitle, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
    }, 'start')
    if(partnersText.length){
      partnersTl.from(partnersText, {
        duration: 1,
        ease: 'circ.out',
        yPercent: 100,
      }, 'start+=.2')
    }
    partnersTl.from(partnersMarquee, {
      duration: .5,
      ease: 'linear',
      opacity: 0,
    }, 'start+=.4')
  },

  initProductListAnimations(){
    const productListTitle = $('.product-list__title');
    const productList = $('.product-list__wrap');
    if(productListTitle.length == 0) return false


    productListTitle.wrap('<div class="line-wrapper">');

    gsap.timeline({
      scrollTrigger: {
        trigger: productListTitle,
        start: "top 75%",
      },
    })
    .addLabel('start')
    .from(productListTitle, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
    }, 'start')
    .from(productList, {
      duration: .5,
      ease: 'linear',
      opacity: 0,
    }, 'start+=.4')
  },

  initUtilityBoxesAnimations(){
    const utilityBoxes = $('.utility-boxes');
    if(utilityBoxes.length == 0) return false

    gsap.timeline({
      scrollTrigger: {
        trigger: utilityBoxes,
        start: "top 75%",
      },
    })
    .from(utilityBoxes, {
      duration: .5,
      ease: 'linear',
      opacity: 0,
    })
  },

  initRelatedAnimations(){
    const relatedTitle = $('.sector-related__title');
    const related = $('.sector-related__wrap');
    if(relatedTitle.length == 0) return false


    relatedTitle.wrap('<div class="line-wrapper">');

    gsap.timeline({
      scrollTrigger: {
        trigger: relatedTitle,
        start: "top 75%",
      },
    })
    .addLabel('start')
    .from(relatedTitle, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
    }, 'start')
    .from(related, {
      duration: .5,
      ease: 'linear',
      opacity: 0,
    }, 'start+=.4')
  },

  initRequestRowAnimations(){
    const requestRow = $('.request-row');
    if(requestRow.length == 0) return false

    gsap.timeline({
      scrollTrigger: {
        trigger: requestRow,
        start: "top 75%",
      },
    })
    .from(requestRow, {
      duration: .5,
      ease: 'linear',
      opacity: 0,
    })
  },

  initHighlights(){
    const highlights = $('.highlights');
    if(highlights.length == 0) return false

    const highlightsTitle = $('.highlights__title');
    const highlightsText = $('.highlights__text');
    const highlightsList = $('.single-highlights');

    const highlightsTitleSplit = new SplitText(highlightsTitle, {
      type: 'lines,',
      linesClass: 'split-line',
    });
    const highlightsTextSplit = new SplitText(highlightsText, {
      type: 'lines,',
      linesClass: 'split-line',
    });

    $('.highlights__title .split-line, .highlights__text .split-line').wrap('<div class="line-wrapper">');

    gsap.timeline({
      scrollTrigger: {
        trigger: highlights,
        start: "top 75%",
      },
    })
    .from(highlightsTitleSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    })
    .addLabel('start')
    .from(highlightsTextSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    }, 'start-=.5')
    .addLabel('end')
    .from(highlightsList, {
      opacity: 0,
      y: 50,
      ease: "power2.out",
      duration: 1,
      stagger: 0.1
    }, 'end-=.2')
  }
}

module.exports = commonAnimations;
