import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

const productsAnimations = {
  initLoad() {
    this.loadproductsAnimations();
  },

  loadproductsAnimations() {
    this.initTechHighlights();
    this.initVideoAnim();
    this.initExtraBoxes();
    this.initRedMoreButton();
    this.initTabs();
    this.initForm();
  },

  initTechHighlights(){
    const techHighlights = $('.technical-highlights');
    if(techHighlights.length == 0) return false

    const techHighlightsTitle = $('.technical-highlights__title');
    const techHighlightsText = $('.technical-highlights__text');
    const techHighlightsList = $('.single-th');

    const techHighlightsTitleSplit = new SplitText(techHighlightsTitle, {
      type: 'lines,',
      linesClass: 'split-line',
    });
    const techHighlightsTextSplit = new SplitText(techHighlightsText, {
      type: 'lines,',
      linesClass: 'split-line',
    });

    $('.technical-highlights__title .split-line, .technical-highlights__text .split-line').wrap('<div class="line-wrapper">');

    gsap.timeline({
      scrollTrigger: {
        trigger: techHighlights,
        start: "top 75%",
      },
    })
    .from(techHighlightsTitleSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    })
    .addLabel('start')
    .from(techHighlightsTextSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    }, 'start-=.5')
    .addLabel('end')
    .from(techHighlightsList, {
      opacity: 0,
      y: 50,
      ease: "power2.out",
      duration: 1,
      stagger: 0.1
    }, 'end-=.2')
  },

  initVideoAnim() {
    const videoRow = $('.product-video-row');
    if(videoRow.length == 0) return false

    const videoRowTitle = $('.product-video-row__title');
    const videoRowText = $('.product-video-row__text');
    const videoRowList = $('.product-video-row__wrap');

    const videoRowTitleSplit = new SplitText(videoRowTitle, {
      type: 'lines,',
      linesClass: 'split-line',
    });
    const videoRowTextSplit = new SplitText(videoRowText, {
      type: 'lines,',
      linesClass: 'split-line',
    });

    $('.product-video-row__title .split-line, .product-video-row__text .split-line').wrap('<div class="line-wrapper">');

    gsap.timeline({
      scrollTrigger: {
        trigger: videoRow,
        start: "top 75%",
      },
    })
    .from(videoRowTitleSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    })
    .addLabel('start')
    .from(videoRowTextSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    }, 'start-=.5')
    .addLabel('end')
    .from(videoRowList, {
      opacity: 0,
      y: 50,
      ease: "power2.out",
      duration: 1,
    }, 'end-=.6')
  },

  initExtraBoxes() {
    const extraBoxes = $('.product-extra-boxes');
    if(extraBoxes.length == 0) return false

    const extraBoxesTitle = $('.product-extra-boxes__title');
    const extraBoxesText = $('.product-extra-boxes__text');
    const extraBoxesList = $('.extra_box');

    const extraBoxesTitleSplit = new SplitText(extraBoxesTitle, {
      type: 'lines,',
      linesClass: 'split-line',
    });
    const extraBoxesTextSplit = new SplitText(extraBoxesText, {
      type: 'lines,',
      linesClass: 'split-line',
    });

    $('.product-extra-boxes__title .split-line, .product-extra-boxes__text .split-line').wrap('<div class="line-wrapper">');

    gsap.timeline({
      scrollTrigger: {
        trigger: extraBoxes,
        start: "top 75%",
      },
    })
    .from(extraBoxesTitleSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    })
    .addLabel('start')
    .from(extraBoxesTextSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    }, 'start-=.5')
    .addLabel('end')
    .from(extraBoxesList, {
      opacity: 0,
      y: 50,
      ease: "power2.out",
      duration: 1,
      stagger: 0.1
    }, 'end-=.2')
  },

  initRedMoreButton() {
    const $plusButton = $('.extra_box .button-rounded');
    if($plusButton.length == 0) return false

    $plusButton.on('click', function(){
      const $el = $(this).closest('.extra_box').find('.extra_box__full');

      if($el.is(':visible')){
        $(this).removeClass('active')
        $el.fadeOut(450);
      } else {
        $(this).addClass('active')
        $el.fadeIn(450);
      }
    })
  },

  initTabs() {
    const productTab = $('.product-tabs');
    if(productTab.length == 0) return false

    const productTabTitle = $('.product-tabs__title');
    const productTabText = $('.product-tabs__text');
    const productTabList = $('.product-tabs__tabs');

    const productTabTitleSplit = new SplitText(productTabTitle, {
      type: 'lines,',
      linesClass: 'split-line',
    });
    const productTabTextSplit = new SplitText(productTabText, {
      type: 'lines,',
      linesClass: 'split-line',
    });

    $('.product-tabs__title .split-line, .product-tabs__text .split-line').wrap('<div class="line-wrapper">');

    gsap.timeline({
      scrollTrigger: {
        trigger: productTab,
        start: "top 75%",
      },
    })
    .from(productTabTitleSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    })
    .addLabel('start')
    .from(productTabTextSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    }, 'start-=.5')
    .addLabel('end')
    .from(productTabList, {
      opacity: 0,
      y: 50,
      ease: "power2.out",
      duration: 1,
    }, 'end-=.6')

    // Imposto la prima tab come attiva
    $('.tabs__header').find('.tabs__header__inner').eq(0).addClass('active')
    $('.tabs__body').find('.tabs__body__inner').eq(0).css('display', 'block').addClass('active')

    $('.tabs__header__inner').on('click', function(){
      const index = $(this).index();

      $('.tabs__header__inner').removeClass('active')
      $(this).addClass('active')

      $('.tabs__body__inner').css('display', 'none').removeClass('active')
      $('.tabs__body__inner').eq(index).css('display', 'block').addClass('active')
    })
  },

  initForm() {
    const productForm = $('.product-form');
    if(productForm.length == 0) return false

    const productFormList = $('.product-form__wrap');

    gsap.timeline({
      scrollTrigger: {
        trigger: productForm,
        start: "top 75%",
      },
    })
    .from(productFormList, {
      opacity: 0,
      y: 50,
      ease: "power2.out",
      duration: 1,
    }, 'end-=.6')
  }
}

module.exports = productsAnimations;
