import $ from 'jquery'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import '../sass/main.scss';
import utils from './utils/utils';
import menu from './menu';
import smoothState from './smoothState';
import scroll from "./scroll";
import cursor from './cursor';
import overlayPath from './overlayPath';
import bgEffect from './bg-effect';
import animations from './animations'
import hero from './hero';
import marquee from './marquee';
import plus from './plus';
import review from './sectors/review';
import maps from './applications/maps';
import productsAnimations from './products/products-animations';
import masonry from './masonry';
import services from './services/services';
import popup from './popup/popup';
import contacts from './contacts';

window.$ = require('jquery');
window.jQuery = require('jquery');
gsap.registerPlugin(ScrollTrigger);

const siteGlobal = {
  initReady() {
    utils.initReady()
    smoothState.initReady();
    bgEffect.initLoad()

    if(!utils.is_touch_device()) cursor.initReady()
    else cursor.destroy();

  },
  initLoad() {
    scroll.initLoad();
    menu.initLoad();
    hero.initLoad();
    animations.initLoad()
    marquee.initLoad()
    plus.initLoad()
    review.initLoad()
    maps.initLoad()
    productsAnimations.initLoad()
    masonry.initLoad()
    services.initLoad()
    popup.initLoad()
    contacts.initLoad()

    setTimeout(() => {
      ScrollTrigger.refresh()
    }, 500);
  },
  rebuildAllEvents() {
    siteGlobal.initLoad()
  },
  initScroll( ){
    menu.initScroll();
  },
  initResize() {
    bgEffect.initResize()
  },
  rebuild() {
    siteGlobal.rebuildAllEvents();
  },
};

$(document).ready(() => {
  siteGlobal.initReady();
});

$(document).on('rebuild', () => {
  siteGlobal.rebuild();
});

$(window).on('load', () => {
  overlayPath.initTransitionIn()

  setTimeout(() => {
    $('#preloader').addClass('loaded')
    utils.startingDelay = 0.6
    overlayPath.initTransitionOut()
  }, 1500);

  siteGlobal.initLoad();
});

$(window).on('resize', () => {
  siteGlobal.initResize();
});

$(window).on('scroll', () => {
  siteGlobal.initScroll();
});
