
const contacts = {
	initLoad() {
		this.avoidMultipleFormSubmission();
	},

	avoidMultipleFormSubmission() {
		const $el = $('.wpcf7-form');
		if($el.length) {
			// $('.wpcf7-form').find('input[type="submit"]').on('click', function(){
			// 	$(this).attr('disabled')
			// })

			$('input.wpcf7-submit[type="submit"]').on('click', function() {
				var disabled = $(this).attr('data-disabled');
				if (disabled && disabled == "disabled") {
					return false;
				} else {
					$(this).attr('data-disabled',"disabled");
					return true;
				}
			});
			$('.wpcf7').on("wpcf7submit",function(){
				$(this).find('input.wpcf7-submit[type="submit"]').attr('data-disabled',"enabled");
			});
		}
	},
}

export default contacts;
