import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import "slick-carousel";

gsap.registerPlugin(SplitText);

const review = {

  initLoad() {
    this.initReviewCarousel();
    this.initReviewAnimations();
	},

  initReviewAnimations(){;
    const reviewCarousel = $('.sectors-review__carousel');
    if(reviewCarousel.length == 0) return false

    gsap.timeline({
      scrollTrigger: {
        trigger: reviewCarousel,
        start: "top 75%",
      },
    })
    .from(reviewCarousel, {
      duration: .5,
      ease: 'linear',
      opacity: 0,
    })
  },

  initReviewCarousel(){
    if(!$('.sectors-review__carousel').length) return false

    $('.sectors-review__carousel').slick({
      dots: false,
      arrows: true,
      infinite: true,
      speed: 800,
      autoplay: false,
      autoplaySpeed: 4000,
      centerMode: true,
      centerPadding: '22%',
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            centerPadding: '25px',
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
}

module.exports = review;
