import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import "slick-carousel";

gsap.registerPlugin(SplitText);

const plus = {
  selector: null,

  initLoad() {
    plus.selector = $('.plus-slider')

		if(plus.selector.length){
      this.initPlusAnimation();
      this.initPlusCarousel();
      this.initRedMoreButton();
    }
	},

	initPlusAnimation() {
    const plusTitle = $('.plus-slider__title')

    const plusTitleSplit = new SplitText(plusTitle, {
      type: 'lines,',
      linesClass: 'split-line',
    });

    $('.plus-slider__title .split-line').wrap('<div class="line-wrapper">');

    gsap.timeline({
      scrollTrigger: {
        trigger: plusTitle,
        start: "top 75%",
      },
    })
    .from(plusTitleSplit.lines, {
      duration: 1,
      ease: 'circ.out',
      yPercent: 100,
      stagger: 0.2,
    })
  },

  initPlusCarousel(){
    $('.carousel').slick({
      dots: true,
      arrows: false,
      infinite: true,
      speed: 800,
      autoplay: true,
      autoplaySpeed: 10000,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  },

  initRedMoreButton() {
    const $plusButton = $('.plus-slider .button-rounded');
    if($plusButton.length == 0) return false

    $plusButton.on('click', function(){
      const $el = $(this).closest('.carousel__plus').find('.carousel__plus__text');

      if($el.is(':visible')){
        $(this).removeClass('active')
        $el.fadeOut(450);
      } else {
        $(this).addClass('active')
        $el.fadeIn(450);
      }
    })
  }
}

module.exports = plus;
