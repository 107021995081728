import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const scroll = {
  scroller: null,

  initLoad() {
    scroll.initSmoothScroll()
  },

  initSmoothScroll() {

    scroll.scroller = ScrollSmoother.create({
      content: '.pageWrapper',
      wrapper: '#main',
      smooth: 1.1, // how long (in seconds) it takes to “catch up” to the native scroll position
      effects: true, // looks for data-speed and data-lag attributes on elements
      smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });
  }
}

module.exports = scroll;
