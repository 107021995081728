import scroll from './scroll';
import { gsap } from "gsap";

const menu = {
	isAnim: 0,
	submenuIsAnim: 0,

	initLoad() {
		if($(window).outerWidth() >= 768) this.menuInit();
		if($(window).outerWidth() >= 768) this.burgerMenuInit();
		if($(window).outerWidth() >= 768) this.showProductImage();
		this.checkStickyHeader();
		if($(window).outerWidth() < 768) this.mobileMenuInit();
	},

	initScroll(){
		this.checkStickyHeader();
		if($(window).outerWidth() > 768) this.closeMenuOnScroll();
	},

	menuInit() {
		const $el = $('.main-menu__item > a')

		$el.on('click', function() {
			const menuOpened = $('.main-menu__item.opened .sub-menu')
			const thisMenu = $(this).parent().find('.sub-menu');
			const menuItems = $('.main-menu__item.opened .sub-menu li a')
			const thisMenuItems = $(this).parent().find('.sub-menu li a')
			const menuItemsSpan = $('.main-menu__item.opened .sub-menu .span-wrapper span')
			const thisMenuItemsSpan = $(this).parent().find('.sub-menu .span-wrapper span')

			if (menu.submenuIsAnim != 0) return false
			menu.submenuIsAnim = 1

			if($(this).parent().hasClass('opened')){

				gsap
				.timeline({
					onComplete: () => {
						$(this).parent().removeClass('opened')
						$('.header').removeClass('filled')

						menu.submenuIsAnim = 0;
					}
				})
				.addLabel('middle')
				.to(thisMenuItems,
					{
						duration: .6,
						ease: "power2.in",
						yPercent: 0,
						stagger: { each: 0.1 },
					}, 'middle'
				)
				.to(thisMenuItemsSpan,
					{
						duration: .6,
						ease: "power2.in",
						yPercent: 0,
						stagger: { each: 0.1 },
					}, 'middle'
				)
				.to(thisMenu, {
					duration: .5,
					ease: "power4.easeInOut",
					scaleY: 0,
				})

			} else {
				$('.header').addClass('filled')

				$el.parent().removeClass('opened')
				$(this).parent().addClass('opened')

				const tl = gsap
				.timeline({
					onComplete: () => {
						menu.submenuIsAnim = 0;
					}
				})
				if(menuOpened.length){
					tl.addLabel('start')
					.to(menuItemsSpan,
						{
							ease: "power2.in",
							yPercent: 0,
							stagger: { each: 0.1 },
						}, 'start'
					)
					.to(menuItems,
						{
							ease: "power2.in",
							yPercent: 0,
							stagger: { each: 0.1 },
						}, 'start'
					)
					.to(menuOpened,
						{
							duration: .5,
							ease: "power4.easeInOut",
							scaleY: 0,
						}
					)
				}
				tl.addLabel('middle')
				.to(thisMenu, {
					duration: .5,
					ease: "power4.easeInOut",
					scaleY: 1,
				}, 'middle')
				.addLabel('end')
				.to(thisMenuItemsSpan,
					{
						duration: .6,
						ease: "power2.in",
						yPercent: -100,
						stagger: { each: 0.1 },
					}, 'end-=.7'
				)
				.to(thisMenuItems,
					{
						duration: .6,
						ease: "power2.in",
						yPercent: -100,
						stagger: { each: 0.1 },
					}, 'end-=.7'
				)
			}
		})
	},

	burgerMenuInit() {
		const burgerMenu = $('.burger-menu')
		const menuLabelItems = $('.burger-menu__col span')
		const menuItems = $('.burger-menu__col li a')

		$('.header__hamburger').on('click',() => {
			if (menu.isAnim != 0) return false
			menu.isAnim = 1

			if($('.header__hamburger').hasClass('opened')){
				$('.header__hamburger, .header').removeClass('opened');

				gsap
				.timeline({
					onComplete: () => {
						menu.isAnim = 0;
					}
				})
				.addLabel('start')
				.to(menuItems,
					{
						duration: .1,
						ease: "power2.out",
						yPercent: 0,
						stagger: { each: 0.1 },
					}, 'start'
				)
				.to(menuLabelItems,
					{
						duration: .1,
						ease: "power2.out",
						yPercent: 0,
						stagger: { each: 0.3 },
					}, 'start'
				)
				.addLabel('middle')
				.to(burgerMenu,
					{
						duration: 1.6,
						ease: "power4.out",
						xPercent: 0
					}, 'middle-=.1'
				)
			} else {
				$('.header__hamburger, .header').addClass('opened');

				gsap
				.timeline({
					onComplete: () => {
						menu.isAnim = 0;
					}
				})
				.addLabel('start')
				.to(burgerMenu,
					{
						duration: 1.2,
						ease: "power4.out",
						xPercent: () => -100
					}, 'start'
				)
				.to(menuLabelItems,
					{
						duration: 1.2,
						ease: "power2.in",
						yPercent: -100,
						stagger: { each: 0.3 },
					}, 'start-=.5'
				)
				.to(menuItems,
					{
						duration: 1.2,
						ease: "power2.in",
						yPercent: -100,
						stagger: { each: 0.1 },
					}, 'start'
				)
			}
		})
	},

	checkStickyHeader() {
		const $header = $('.header')

		if(
			$('.base-template').length > 0 ||
			$('.page_OFFICES').length > 0 ||
			$('.page_PRESS').length > 0 ||
			$('.page_WEBINAR').length > 0 ||
			$('.page_CASE-STUDIES').length > 0 ||
			$('.page_WORKWITHUS').length > 0 ||
			$('.page_EVENTS').length > 0
		){
			/* Sono nelle pagine senza hero*/
			$header.addClass('sticky');
		} else {
			if ($(window).scrollTop() > 150) $header.addClass('sticky')
			else $header.removeClass('sticky')
		}
	},

	showProductImage() {
		const $el = $('.sub-menu__row a')

		$el.each(function(i, el){

			$(el).on('mouseenter', function() {
				const dataID = $(this).data('el-id');
				const currentImageToShow = $('.sub-menu__imgwrap[data-el-id="'+dataID+'"] img')

				if(currentImageToShow.length){
					gsap
					.timeline()
					.to(currentImageToShow,
						{
							duration: 1,
							ease: "power2.out",
							opacity: 1,
							scale: 1
						})
				}
			});

			$(el).on('mouseleave', function() {
				const currentImageToHide= $('.sub-menu__imgwrap img')

				gsap
				.timeline()
				.to(currentImageToHide,
					{
						duration: 1,
						ease: "power2.out",
						opacity: 0,
						scale: 1.1
					})
			});
		})
	},

	mobileMenuInit(){
		const burgerMenu = $('.burger-menu')

		$('.header__hamburger').on('click',() => {
			if (menu.isAnim != 0) return false
			menu.isAnim = 1

			if($('.header__hamburger').hasClass('opened')){
				$('.header__hamburger, .header').removeClass('opened');

				gsap
				.timeline({
					onComplete: () => {
						menu.isAnim = 0;
					}
				})
				.to(burgerMenu,
					{
						duration: 1.6,
						ease: "power4.out",
						xPercent: 0
					}, 'middle-=.1'
				)
			} else {
				$('.header__hamburger, .header').addClass('opened');

				gsap
				.timeline({
					onComplete: () => {
						menu.isAnim = 0;
					}
				})
				.to(burgerMenu,
					{
						duration: 1.2,
						ease: "power4.out",
						xPercent: () => -100
					},
				)
			}
		})

		$('.burger-menu-mobile .menu > li > a').on('click', function(){
			$('.sub-menu-mobile').slideUp(400)
			if(!$(this).parent().find('.sub-menu-mobile').is(':visible')) $(this).parent().find('.sub-menu-mobile').slideDown(400)
		})
	},

	closeMenuOnScroll() {
		if($('.main-menu__item.opened').length > 0){
			$('.main-menu__item.opened > a').trigger('click')
		}
	}
}

module.exports = menu;
