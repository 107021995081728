import Isotope from 'isotope-layout'

import scroll from './scroll'

const masonry = {
	iso: null,

	initLoad() {
		if ($('.load-more__button').length) {
			if ($('.page_EVENTS').length > 0) {
				this.initEventsLoadMore()
			} else {
				this.initNewsLoadMore()
			}
		}
		this.initNewsMasonry()
	},

	initNewsLoadMore() {
		$('.load-more__button').on('click', function (event) {
			event.preventDefault()
			var curr_pagination = $('.load-more__button').data('page')
			var post_type = $('.masonry').data('post-type')
			var cat = $('.filters__wrap').find('.button.active').attr('data-filter')

			$('.load-more__loader').addClass('loading')
			$('.load-more__button').animate(
				{
					opacity: 0,
				},
				100
			)

			$.ajax({
				url: '/wp-admin/admin-ajax.php',
				type: 'POST',
				data: {
					action: 'load_more_news',
					page: curr_pagination,
					post_type,
					cat,
				},
				success: function (html) {
					curr_pagination++

					$('.load-more__loader').removeClass('loading')
					$('.load-more__button').data('page', curr_pagination)
					setTimeout(function () {
						$('.masonry__grid').append(html)
						masonry.iso = new Isotope(document.querySelector('.masonry__grid'))
						masonry.iso.arrange()

						scroll.scroller.refresh()

						$('.load-more__button').animate(
							{
								opacity: 1,
							},
							100
						)
					}, 150)
				},
			})
		})
	},
	initEventsLoadMore() {
		$('.load-more__button').on('click', function (event) {
			event.preventDefault()
			var curr_pagination = $('.load-more__button').data('page')

			$('.load-more__loader').addClass('loading')
			$('.load-more__button').animate(
				{
					opacity: 0,
				},
				100
			)

			$.ajax({
				url: '/wp-admin/admin-ajax.php',
				type: 'POST',
				data: {
					action: 'load_more_events',
					page: curr_pagination,
				},
				success: function (html) {
					curr_pagination++

					$('.load-more__loader').removeClass('loading')
					$('.load-more__button').data('page', curr_pagination)

					setTimeout(function () {
						$('.events__grid').append(html)
						// masonry.iso = new Isotope(document.querySelector('.masonry__grid'));

						// const filterValue = $('.filters__wrap .active').attr('data-filter');
						// masonry.iso.arrange({
						//   filter: filterValue
						// });

						scroll.scroller.refresh()

						$('.load-more__button').animate(
							{
								opacity: 1,
							},
							100
						)
					}, 150)
				},
			})
		})
	},
	initNewsMasonry() {
		const $el = $('.masonry__grid')
		if ($el.length == 0) return false

		const grid = document.querySelector('.masonry__grid')
		masonry.iso = new Isotope(grid)

		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const cat = urlParams.get('cat')
		if (cat) {
			$('.filters__wrap')
				.find('.button[data-filter="' + cat + '"]')
				.addClass('active')
		} else {
			$('.filters__wrap').find('.button[data-filter="*"]').addClass('active')
		}
	},
}

module.exports = masonry
