import { gsap } from "gsap";

const overlayPath = {
  selector: document.querySelector(".overlay__path"),

	initTransitionIn() {
    gsap
    .timeline()
    .set(overlayPath.selector, {
      attr: { d: "M 0 100 V 100 Q 50 100 100 100 V 100 z" },
    })
    .to(
      overlayPath.selector,
      {
        duration: 0.8,
        ease: "power4.in",
        attr: { d: "M 0 100 V 50 Q 50 0 100 50 V 100 z" },
      },
      0
    )
    .to(overlayPath.selector, {
      duration: 0.3,
      ease: "power2",
      attr: { d: "M 0 100 V 0 Q 50 0 100 0 V 100 z" },
      onComplete: () => {
      },
    });
  },

  initTransitionOut() {
    gsap
      .timeline()
      .set(overlayPath.selector, {
        attr: { d: "M 0 0 V 100 Q 50 100 100 100 V 0 z" },
      })
      .to(overlayPath.selector, {
        duration: 0.3,
        ease: "power2.in",
        attr: { d: "M 0 0 V 50 Q 50 0 100 50 V 0 z" },
      })
      .to(overlayPath.selector, {
        duration: 0.8,
        ease: "power4",
        attr: { d: "M 0 0 V 0 Q 50 0 100 0 V 0 z" },
      })
  }
}

module.exports = overlayPath;
