import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

const homeAnimations = {
  initLoad() {
    homeAnimations.loadHomeAnimations();
  },

  loadHomeAnimations() {
    if ($(window).outerWidth() >= 768) this.sectorsInit();
    if ($(window).outerWidth() >= 768) this.applicationsInit();
    this.utilityBoxesInit();
  },

  sectorsInit() {
    const sectorsSection = $('.section-home-sector');
    const sectors = $('.sectors-list');
    const sectorsTitle = $('.section-home-sector__title');
    if (sectorsTitle.length == 0) return false

    sectorsTitle.wrap('<div class="line-wrapper">');

    //Sposto in su tutto quello che c'è sotto i settori (perchè vengrono translati)
    sectorsSection.css('marginBottom', - sectors.outerHeight() / 2);

    gsap.timeline({
      scrollTrigger: {
        trigger: sectorsTitle,
        start: "top 75%",
      },
    })
      .addLabel('start')
      .from(sectorsTitle, {
        duration: 1,
        ease: 'circ.out',
        yPercent: 100,
      }, 'start')
      .from(sectors, {
        duration: .5,
        ease: 'linear',
        opacity: 0,
      }, 'start+=.4')

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-home-sector',
        start: "top 15%",
        scrub: true,
        pin: '.section-home-sector__inner',
        pinSpacing: true,
      },
    })
    $('.sectors-list__box').each((i, el) => {
      const scale = .8
      tl.to(el, {
        duration: 1.5,
        ease: 'linear',
        scale: scale + i / 10,
        yPercent: -85 * i
      }, "-=1")
    })
  },

  applicationsInit() {
    const applications = $('.application');
    if (applications.length == 0) return false

    const applicationsSection = $('.home-applications');
    const applicationsList = $('.applications-list');
    const applicationsListInner = $('.applications-list__inner');
    const sideLine = $('.home-applications__sideline__inner')

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: applicationsSection,
        start: "top -10%",
        scrub: true,
        pin: true,
        pinSpacing: true,
      },
    })
      .addLabel('start')
      .to(applicationsListInner, {
        ease: 'linear',
        y: - applicationsListInner.outerHeight() + $(applications[applications.length -1]).outerHeight()
      }, 'start')
      .to(sideLine, {
        ease: 'linear',
        scaleY: 1
      }, 'start')

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: applicationsList,
        start: "top 35%",
        scrub: true,
      },
    })
    applications.each((i, el) => {
      tl2.to(el, {
        ease: 'linear',
        opacity: 1,
      })
      tl2.addLabel('test')

      if(i < applications.length && i != 0){
        tl2.to($('.applications-images .applications-images__image').eq(i), {
          ease: 'linear',
          // clipPath: "inset(0 0 100% 0)"
          yPercent: -100
        }, 'test')

        tl2.to($('.applications-images .applications-images__image').eq(i).find('img'), {
          ease: 'linear',
          // clipPath: "inset(0 0 100% 0)"
          yPercent: 5
        }, 'test')
      }

    })
  },

  utilityBoxesInit() {
    const ubList = $('.home-utility-boxes');
    if (ubList.length == 0) return false

    const ubSingle = $('.home-utility-boxes .box__wrap');

    gsap.timeline({
      scrollTrigger: {
        trigger: ubList,
        start: "top 75%",
      },
    })
      .from(ubSingle, {
        ease: 'linear',
        opacity: 0,
        stagger: .2,
        yPercent: 10
      })
  },
}

module.exports = homeAnimations;
