import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

/* ANIMAZIONI */
import commonAnimations from './common-animations';
import homeAnimations from './home/home-animations';

gsap.registerPlugin(ScrollTrigger);

const animations = {
  initLoad() {
    this.importAnims();
  },
  importAnims() {
    commonAnimations.initLoad();
    if($('.page_HOME').length){
      homeAnimations.initLoad();
    }
  },
}

module.exports = animations;
