import smoothState from 'smoothState';
import overlayPath from './overlayPath';

const pageTransition = {
	initReady() {
		this.initPageTransition();
	},

	initPageTransition() {
		const options = {
			anchors: 'a',
			forms: 'form',
			allowFormCaching: false,
			scroll: false,
			blacklist: '.no-smoothState , .no-smoothState > a, .wpcf7-form,	a:not([href]), ._form',
			prefetch: false,
			cacheLength: 0,
			onBefore: function($currentTarget, $container) {
				$('#cursor').attr('class', 'active loading')
			},
			onProgress: {
				// How long this animation takes
				duration: 1500,
				// A function that dictates the animations that take place
				render: function ($container) {
					overlayPath.initTransitionIn()
				}
			},
			onStart: {
				duration: 400,
				render: function ($container) {
				}
			},
			onReady: {
				render: function ($container, $newContent) {
					$container.html($newContent);

					window.scrollTo(0, 0);
				}
			},
			onAfter: function($container, $newContent){
					$(document).trigger('rebuild');

					$('#cursor').removeClass('loading')

					var cf_selector = 'div.wpcf7 > form';
					var cf_forms = $newContent.find(cf_selector);
					if (cf_forms.length>0) {
						$(cf_selector).each(function() {
							var $form = $(this);
							wpcf7.init( $form[0] );
						});
					}

					setTimeout(function(){
						overlayPath.initTransitionOut()
					}, 350);
			}
		};

		$('#main').smoothState(options).data('smoothState');
  	},

}

export default pageTransition;
