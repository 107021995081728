const popup = {
  initLoad() {
    if(document.querySelector('.popup')) {
      this.checkCookie()
      this.controls()
    }
  },
  checkCookie(){
    if (popup.getCookie('accepted') == 'on') {
      console.log('cookie value = ' + popup.getCookie('accepted'));
      $('.popup').addClass('hidden');
    } else {
      $('.popup').removeClass('hidden');
    }
  },
  controls() {
    const $checkbox = $('.popup #accept');
    const $button = $('.popup .button');

    $checkbox.change(function() {
      if (this.checked) {
        $button.removeClass('disabled')
      } else {
        $button.addClass('disabled')
      }
    })

    $button.on('click', ()=>{
      $('.popup').addClass('hidden');

      $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'POST',
        data: {
          action: 'update_cookie',
        },
        success: function(data) {

          // console.log(data);
          $('.popup').addClass('hidden');
          console.log(popup.getCookie('accepted'));
          
        }
      })

    })
  },
  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
}

export default popup;