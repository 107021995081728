const services = {
	initLoad() {
		this.initServicesModals();
	},

	initServicesModals() {
		const $el = $('.circle-selector__modals');
		if($el.length == 0) return false

		$('.circle-selector__item').on('click', function(){
			const index = $(this).index();

			$('.circle-selector__modal').eq(index - 1).fadeIn(450)
		})

		$('.circle-selector__modal__close').on('click', function(){
			$('.circle-selector__modal').fadeOut(450)
		})
	},
}

export default services;
