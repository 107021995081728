import scroll from "./scroll";
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import utils from './utils/utils'

gsap.registerPlugin(SplitText, ScrollTrigger);

const hero = {
  selector: null,

	initLoad() {
    hero.selector = $('.hero')

		if(hero.selector.length){
      this.heroAnimations();
      this.initScrollButton();
    }
	},

	heroAnimations() {
    const heroImage = $('.hero__media')
    const heroTitle = $('.hero__title')
    const heroText = $('.hero__text')
    const heroButton = $('.hero .button')
    let heroTextSplit = null

    const heroTitleSplit = new SplitText(heroTitle, {
      type: 'lines,',
      linesClass: 'split-line',
    });

    if(heroText.length){
      heroTextSplit = new SplitText(heroText, {
        type: 'lines,',
        linesClass: 'split-line',
      });
    }

    $('.hero .split-line').wrap('<div class="line-wrapper">');

    const tl = gsap.timeline()
      .delay(utils.startingDelay)
      .addLabel('start')
      .to(heroImage, { scale: 1, duration: 1, ease: "power2.out" }, 'start')
      .from(heroTitleSplit.lines, {
        duration: 1,
        ease: 'circ.out',
        yPercent: 100,
        stagger: 0.2,
      }, 'start+=.2')
      .addLabel('middle')
      if(heroText.length){
        tl.from(heroTextSplit.lines, {
          duration: 0.4,
          ease: 'circ.out',
          yPercent: 100,
          stagger: 0.2,
        }, 'middle-=.3')
      }
      tl.from(heroButton, {
        opacity: 0,
        ease: "power2.out",
        duration: 1
      })
  },

  initScrollButton() {
    const heroButton = $('.hero .button')
    const ww = $(window).outerWidth();
    const wh = $(window).outerHeight();
    const offset = ww > 768 ? wh : `${wh} 50px`;
    const duration = ww > 768 ? 0.6 : 0.8;

    heroButton.on('click', function(){
      gsap.to(scroll.scroller, {
        // don't let it go beyond the maximum scrollable area
        scrollTop: Math.min(ScrollTrigger.maxScroll(window), scroll.scroller.offset('.hero', offset)),
        duration
      });;
    })
    //scroll.scroller.scrollTo(wh, true))
  }

}

module.exports = hero;
